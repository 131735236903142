import { createStore } from 'vuex'
import UserModel from '../models/UserModel';

export default createStore({
  state: {
    user: new UserModel(
      '12',
      'John Doe',
      [],
      0,
      0,
      0,
      0,
      0,
      [],
      Date.now(),
      0,
    ),
    mineBoxes: [],
    claimBoxes: [],
    missions: [],
    linksDone: [],
    canClaim: false,
    verifyingMission: [],
    nextClaim: Date.now(),
    nextDailyClaim: Date.now(),
    nextWheelClaim: Date.now(),
    countdown: '00:00:00',
    nextDailyClaimCountdown: '00:00:00',
    wheelCountDown: '00:00:00',
    countDownInterval: null,
    dailyCountDownInterval: null,
    wheelCountDownInterval: null,
    selectedTab: 'missions',
    leaderboard: [],
    comboCountdown: '00:00:00',
    comboCountdownInterval: null,
    nextComboClaim: Date.now(),
    nextAdMissionClaim: Date.now(),
    adMissionCountdown: '00:00:00',
    adMissionCountdownInterval: null,
  },
  getters: { 
    user: state => state.user,
    boxes: state => state.boxes,
    missions: state => state.missions,
    linksDone: state => state.linksDone,
    canClaim: state => state.canClaim,
    verifyingMission: state => state.verifyingMission, 
    nextClaim: state => state.nextClaim,
    nextDailyClaim: state => state.nextDailyClaim,
    nextWheelClaim: state => state.nextWheelClaim,
    countdown: state => state.countdown,
    nextDailyClaimCountdown: state => state.nextDailyClaimCountdown,
    countDownInterval: state => state.countDownInterval,
    dailyCountDownInterval: state => state.dailyCountDownInterval,
    wheelCountDown: state => state.wheelCountDown,
    wheelCountDownInterval: state => state.wheelCountDownInterval,
    selectedTab: state => state.selectedTab,
    leaderboard: state => state.leaderboard,
    comboCountdown: state => state.comboCountdown,
    nextComboClaim: state => state.nextComboClaim,
    nextAdMissionClaim: state => state.nextAdMissionClaim,
    adMissionCountdown: state => state.adMissionCountdown,
    adMissionCountdownInterval: state => state.adMissionCountdownInterval,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setStorage(state, storage) {
      state.user.storage = storage;
    },
    setMineBoxes(state, mineBoxes) {
      state.mineBoxes = mineBoxes;
    },
    setClaimBoxes(state, claimBoxes) {
      state.claimBoxes = claimBoxes;
    },
    setMiningLevel(state, level) {
      state.user.boxLevel = level;
    },
    setClaimLevel(state, level) {
      state.user.claimLevel = level;
    },
    setMinePerSecond(state, minePerSecond) {
      state.user.minePerSecond = minePerSecond;
    },
    setBalance(state, balance) {
      state.user.balance = balance;
    },
    setMissions(state, missions) {
      state.missions = missions;
    },
    setMissionCompleted(state, missionId) {
      state.user.missionsDone.push(missionId);
    },
    setClaimFillDurationHours(state, hours) {
      state.user.claimFillDurationHour = hours;
    },
    setLastClaimed(state, lastClaimed) {
      state.user.lastClaimed = lastClaimed;
    },
    addLinkDone(state, link) {
      state.linksDone.push(link);
    },
    removeLinkDone(state, link) {
      state.linksDone = state.linksDone.filter(l => l !== link);
    },
    setCanClaim(state, canClaim) {
      state.canClaim = canClaim;
    },
    addVerifyingMission(state, mission) {
      state.verifyingMission.push(mission);
    },
    removeVerifyingMission(state, mission) {
      state.verifyingMission = state.verifyingMission.filter(m => m !== mission);
    },
    setRefClaimLevel(state, level) {
      state.user.refClaimLevel = level;
    },
    setNextClaim(state, nextClaim) {
      state.nextClaim = nextClaim;
    },
    setCountdown(state, countdown) {
      state.countdown = countdown;
    },
    setNextDailyClaim(state, nextDailyClaim) {
      state.nextDailyClaim = nextDailyClaim;
    },
    setDailyCountdown(state, countdown) {
      state.nextDailyClaimCountdown = countdown;
    },
    setCountdownInterval(state, interval) {
      state.countDownInterval = interval;
    },
    setDailyCountDownInterval(state, interval) {
      state.dailyCountDownInterval = interval;
    },
    setNextWheelClaim(state, nextWheelClaim) {
      state.nextWheelClaim = nextWheelClaim;
    },
    setWheelCountDown(state, countdown) {
      state.wheelCountDown = countdown;
    },
    setWheelCountDownInterval(state, countdown) {
      state.wheelCountDownInterval = countdown;
    },
    setSelectedTab(state, tab) {
      state.selectedTab = tab;
    },
    setLeaderboard(state, leaderboard) {
      state.leaderboard = leaderboard;
    },
    setComboCountdown(state, countdown) {
      state.comboCountdown = countdown;
    },
    setNextComboClaim(state, nextComboClaim) {
      state.nextComboClaim = nextComboClaim;
    },
    setComboCountdownInterval(state, interval) {
      state.comboCountdownInterval = interval;
    },
    setNextAdMissionClaim(state, nextAdMissionClaim) {
      state.nextAdMissionClaim = nextAdMissionClaim;
    },
    setAdMissionCountdown(state, countdown) {
      state.adMissionCountdown = countdown;
    },
    setAdMissionCountdownInterval(state, interval) {
      state.adMissionCountdownInterval = interval;
    },
  },
  actions: {
    setUser({ commit }, user) {
      commit('setUser', user);
    },
    setStorage({ commit }, storage) {
      commit('setStorage', storage);
    },
    setMineBoxes({ commit }, boxes) {
      commit('setMineBoxes', boxes);
    },
    setClaimBoxes({ commit }, boxes) {
      commit('setClaimBoxes', boxes);
    },
    setMiningLevel({ commit }, level) {
      commit('setMiningLevel', level);
    },
    setClaimLevel({ commit }, level) {
      commit('setClaimLevel', level);
    },
    setMinePerSecond({ commit }, minePerSecond) {
      commit('setMinePerSecond', minePerSecond);
    },
    setBalance({ commit }, balance) {
      commit('setBalance', balance);
    },
    setMissions({ commit }, missions) {
      commit('setMissions', missions);
    },
    setMissionCompleted({ commit }, missionId) {
      commit('setMissionCompleted', missionId);
    },
    setClaimFillDurationHours({ commit }, hours) {
      commit('setClaimFillDurationHours', hours);
    },
    setLastClaimed({ commit }, lastClaimed) {
      commit('setLastClaimed', lastClaimed);
    },
    addLinkDone({ commit }, link) {
      commit('addLinkDone', link);
    },
    removeLinkDone({ commit }, link) {
      commit('removeLinkDone', link);
    },
    setCanClaim({ commit }, canClaim) {
      commit('setCanClaim', canClaim);
    },
    addVerifyingMission({ commit }, mission) {
      commit('addVerifyingMission', mission);
    },
    removeVerifyingMission({ commit }, mission) {
      commit('removeVerifyingMission', mission);
    },
    setRefClaimLevel({ commit }, level) {
      commit('setRefClaimLevel', level);
    },
    setNextClaim({ commit }, nextClaim) {
      commit('setNextClaim', nextClaim);
    },
    setCountdown({ commit }, countDown) {
      commit('setCountdown', countDown);
    },
    setNextDailyClaim({ commit }, nextDailyClaim) {
      commit('setNextDailyClaim', nextDailyClaim);
    },
    setDailyCountdown({ commit }, countdown) {
      commit('setDailyCountdown', countdown);
    },
    setNextWheelClaim({ commit }, interval) {
      commit('setNextWheelClaim', interval);
    },
    setWheelCountDown({ commit }, interval) {
      commit('setWheelCountDown', interval);
    },
    setCountdownInterval({ commit }, interval) {
      commit('setCountdownInterval', interval);
    },
    setDailyCountDownInterval({ commit }, interval) {
      commit('setDailyCountDownInterval', interval);
    },
    setWheelCountDownInterval({ commit }, countdown) {
      commit('setWheelCountDownInterval', countdown);
    },
    setSelectedTab({ commit }, tab) {
      commit('setSelectedTab', tab);
    },
    setLeaderboard({ commit }, leaderboard) {
      commit('setLeaderboard', leaderboard);
    },
    setComboCountdown({ commit }, countdown) {
      commit('setComboCountdown', countdown);
    },
    setNextComboClaim({ commit }, nextComboClaim) {
      commit('setNextComboClaim', nextComboClaim);
    },
    setComboCountdownInterval({ commit }, interval) {
      commit('setComboCountdownInterval', interval);
    },
    setNextAdMissionClaim({ commit }, nextAdMissionClaim) {
      commit('setNextAdMissionClaim', nextAdMissionClaim);
    },
    setAdMissionCountdown({ commit }, countdown) {
      commit('setAdMissionCountdown', countdown);
    },
    setAdMissionCountdownInterval({ commit }, interval) {
      commit('setAdMissionCountdownInterval', interval);
    },
  },
  modules: {}
});