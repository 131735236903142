<!-- src/components/DraggableCard.vue -->
<template>
  <div
    class="rounded shadow cursor-pointer"
    :class="{ 'opacity-50': isDragging }"
    draggable="true"
    @dragstart="onDragStart"
    @dragend="onDragEnd"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    cardData: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isDragging: false
    };
  },
  methods: {
    onDragStart(event) {
      this.isDragging = true;
      event.dataTransfer.setData('text/plain', this.index);
    },
    onDragEnd() {
      this.isDragging = false;
    }
  }
}
</script>
