<template>
    <div class="flex flex-wrap gap-2">
      <draggable
        v-model="localCards"
        @end="onDragEnd"
        class="flex flex-wrap gap-2"
        item-key="id"
      >
        <template #item="{ element }">
          <div>
            <DraggableCard :cardData="element">
              <img :src="cardIcon(element.index)" alt="img" style="object-fit: cover; height: 80px; width: 80px;">
              <h5>{{ element.index }}</h5>
            </DraggableCard>
          </div>
        </template>
      </draggable>
    </div>
  </template>
  
  <script>
  import DraggableCard from './DraggableCard.vue';
  import draggable from 'vuedraggable';


  export default {
    name: 'DropZone',
    components: {
      DraggableCard,
      draggable
    },
    props: {
      cards: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        localCards: [...this.cards]
      };
    },
    watch: {
      cards(newCards) {
        this.localCards = [...newCards];
      }
    },
    methods: {
      onDragEnd() {
        this.$emit('update:cards', this.localCards);
      },
      cardIcon(index) {
        if(index == 2) {
          return require(`../assets/combo-${index}.png`);
        }

        return require(`../assets/combo-${index}.jpeg`);
      }
    },
  }
  </script>
  