import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
    apiKey: "AIzaSyAfSgvJTetSLT-sEZ0p-PJd5pmuwCxMAxs",
    authDomain: "novas-app-0.firebaseapp.com",
    projectId: "novas-app-0",
    storageBucket: "novas-app-0.appspot.com",
    messagingSenderId: "13138167518",
    appId: "1:13138167518:web:e61e21b7c3a42226fc644f",
    measurementId: "G-M4V1LHJQ2B"
};

const initFirebase = () => {
    const app = initializeApp(firebaseConfig);
    const analyticsApp = getAnalytics(app);

    return { app, analytics: analyticsApp };
}

export default initFirebase;