class FriendModel {
    constructor(id, name, totalEarned) {
        this.id = id;
        this.name = name;
        this.totalEarned = totalEarned;
    }

    static fromJson(json) {
        return new FriendModel(
            json.id,
            json.name,
            json.totalEarned,
        );
    }
}

export default FriendModel;