<template>
    <div class="flex flex-row justify-center mt-10 w-full">
        <div class="fixed text-inherit bottom-0 bg-black p-2">
          <div class="flex justify-around content-center items-center text-secondary-gray text-base font-bold">
            <router-link to="/" class="flex flex-col items-center justify-center">              
              <img src="../assets/home.svg" alt="home" id="home">
              <h4 class="mt-1">Home</h4>
            </router-link>
            <router-link to="/stats" class="flex flex-col items-center justify-center">
              <img src="../assets/friends.svg" alt="friends" id="friends">
              <h4 class="mt-1">Friends</h4>
            </router-link>
            <router-link to="/missions" class="flex flex-col items-center justify-center">
              <img src="../assets/missions.svg" alt="missions" id="missions">
              <h4 class="mt-1">Missions</h4>
              <div class="absolute top-2 right-52 rounded-full h-5 w-5 bg-white flex justify-center items-center font-bold">
                {{ activeMissions }}
              </div>
            </router-link>
            <router-link to="/leaderboard" class="flex flex-col items-center justify-center">
              <img src="../assets/leaderboard.png" alt="leaderboard" id="leaderboard" class="h-10 w-10">
              <h4 class="mt-1 text-sm">Leader Board</h4>
            </router-link>
            <router-link to="/rewards" class="flex flex-col items-center justify-center">
              <img src="../assets/rewards.png" alt="leaderboard" id="leaderboard" class="h-10 w-10">
              <h4 class="mt-1 text-sm">Rewards</h4>
            </router-link>
          </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'BottomBar',

  mounted() {
    this.listenBottomBar();
  },

  computed: {
    user() {
      return this.$store.state.user;
    },

    activeMissions() {
      return this.$store.state.missions.filter((mission) => !this.user.missionsDone.includes(mission.id)).length;
    },
  },

  methods: {
    listenBottomBar() {
      this.$router.afterEach((to) => {
        const home = document.getElementById('home');
        const stats = document.getElementById('friends');
        const missions = document.getElementById('missions');

        home.classList.remove('text-primary-yellow');
        stats.classList.remove('text-primary-yellow');
        missions.classList.remove('text-primary-yellow');

        if (to.path === '/') {
          home.classList.add('text-primary-yellow');
        } else if (to.path === '/stats') {
          stats.classList.add('text-primary-yellow');
        } else if (to.path === '/missions') {
          missions.classList.add('text-primary-yellow');
        }
      });
    },
  }
}
</script>

<style>

div .fixed {
  width: 100%;
  position: fixed;
}
</style>