<template>
    <div class="flex flex-col h-screen overflow-hidden">
        <h3 class="text-3xl font-semibold text-white">Leaderboard</h3>
        <div class="mt-5 mx-5 flex-1 overflow-y-auto">
            <div
            class="border bg-opacity-10 rounded-2xl p-3 flex items-center justify-between relative mb-4"
            v-for="(user, index) in leaderboard"
            :key="user.name"
            :class="user.telegramId == store.state.user.telegramId ? 'border-[#00D98B] bg-[#193019]' : 'border-[#3F71D4] bg-[#3F71D4]'"
            >
            <div class="flex items-center">
                <div class="relative w-12 h-12 flex justify-center items-center flex-col">
                <div class="absolute w-12 h-12 rounded-full border-secondary-yellow border-4"></div>
                <h4 class="text-secondary-yellow text-xl font-bold">{{ index + 1 }}</h4>
                </div>
                <h4 class="text-white font-bold text-lg ms-3 truncate max-w-[100px]">{{ user.name }}</h4>
            </div>
            <div class="flex items-center gap-2">
                <h4 class="text-white font-semibold text-xl">{{ user?.balance.toFixed(2) }}</h4>
            </div>
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
        </div>
        </div>
  </template>
  
  <script setup>
  import { computed } from 'vue';
  import store from '@/store/index';
  
  const leaderboard = computed(() => store.state.leaderboard);
  </script>
  
<style>
.card {
    border-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.33, rgb(14,173,173)), color-stop(0.67, rgb(0,255,255)));
    border-image: -moz-linear-gradient(center bottom, rgb(14,173,173) 33%, rgb(0,255,255) 67% );
    border-style: solid;
    border-radius: 15px;
    display: flex;
    justify-items: center;
    justify-content: space-between;
    background: linear-gradient(90deg, #3E364880 0%, #3C3E4D00 100%);
}

</style>