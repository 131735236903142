const axios = require('axios');
const { default: config } = require('../config');

// Get boxes
const getBoxes = async () => {
    const res = await axios.get(`${config.baseUrl}/app/boxes`);
    return res.data;
};

const getMissions = async () => {
    const res = await axios.get(`${config.baseUrl}/app/missions`);
    return res.data;
}

const claimMission = async (userId, missionId) => {
    const res = await axios.post(`${config.baseUrl}/user/${userId}/mission`, { missionId });
    return res.data;
};

const getLeaderBoard = async () => {
    const res = await axios.get(`${config.baseUrl}/app/leaderboard`);

    return res.data;
}

module.exports = { getBoxes, getMissions, claimMission, getLeaderBoard };