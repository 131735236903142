import { createApp } from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import router from './router'
import store from './store'
import VueTelegram from 'vue-tg'
import ToastPlugin from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-bootstrap.css';

createApp(App)
    .use(store)
    .use(router)
    .use(VueTelegram)
    .use(ToastPlugin, {position: 'top', duration: 1500})
    .mount('#app');