import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import StatsView from '@/views/StatsView.vue'
import MissionsView from '@/views/MissionsView.vue'
import LeaderBoard from '@/views/LeaderBoard.vue'
import RewardsView from '@/views/RewardsView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/stats',
    name: 'stats',
    component: StatsView,
  },
  { 
    path: '/missions',
    name: 'missions',
    component: MissionsView,
  },
  {
    path: '/leaderboard',
    name: 'leaderboard',
    component: LeaderBoard,
  },
  {
    path: '/rewards',
    name: 'rewards',
    component: RewardsView,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
