<template>
    <div class="p-3 h-full">
        <div class="flex flex-col items-center">
            <div class="rounded-3xl w-full reward-card flex justify-between gap-2 p-2 mt-3 relative overflow-hidden " style="border: 1px solid rgba(101, 74, 130, 0.6);">
              <div class="flex flex-col justify-center items-center">
                <img src="../assets/wheel.svg" alt="wheel" class="ms-2" style="height: 100px; width: 100px;">
                <h3 class="text-white mt-3 font-bold">{{ wheelCountdown }}</h3>
              </div>
              <div class="flex flex-col justify-center me-3 items-end">
                  <h3 class="text-xl text-white font-bold text-end">Fortune Wheel!</h3>
                  <span class="text-primary-gray font-semibold">Play to win rewards.</span>

                  <button class="rounded-full mt-4 text-white text-base font-bold px-6 py-3" v-on:click="openWheelModal" :disabled="!isWheelClaimable" :class="isWheelClaimable ? 'bg-secondary-green ' : 'bg-slate-600'">
                    Play Now!
                  </button>
              </div> 
            </div>
            <div class="rounded-3xl w-full reward-card flex flex-col justify-center items-center p-2 mt-5 relative overflow-hidden" style="border: 1px solid rgba(101, 74, 130, 0.6);">
              <img src="../assets/daily-claim-bg.svg" alt="bg" class="w-full h-40 object-cover absolute -right-3 pointer-events-none" />
              <h3 class="text-white text-lg font-semibold">Daily Reward</h3>
              <h2 class="text-primary-gray font-semibold mt-1">Visit daily to claim your rewards</h2>
              <div class="flex items-center mt-3">
                <div v-for="(day, index) in 7" :key="index" class="flex flex-col items-start">
                  <!-- Circle with check mark -->
                  <div class="flex items-center">
                      <div
                        class="relative flex items-center justify-center w-5 h-5 rounded-full"
                        :class="user.dailyClaims >= day ? 'bg-primary-green border-primary-green' : 'bg-gray-button border border-primary-gray'"
                        style="border-width: 2px;"
                      >
                      <svg viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-2 w-2" :class="user.dailyClaims >= day ? 'text-white' : 'text-primary-gray'">
                        <path d="M9.49534 2.48855C7.9325 4.06463 6.29179 5.71053 4.4788 7.52026C4.16527 7.83341 3.8035 7.99931 3.43346 7.99931C3.06342 7.99931 2.70717 7.83617 2.39432 7.52717C1.7328 6.87324 1.07748 6.21516 0.4449 5.57021C-0.136688 4.9771 -0.151159 4.13376 0.410445 3.56485C0.678499 3.29318 1.0258 3.14387 1.38688 3.14387C1.74796 3.14387 2.11869 3.3001 2.40948 3.58282C2.71337 3.87868 3.00347 4.17938 3.31011 4.49806C3.35077 4.54022 3.39074 4.5817 3.43208 4.62456C3.58299 4.47179 3.73252 4.3204 3.88137 4.16901C4.45951 3.58282 5.00526 3.02912 5.56342 2.46574C6.18291 1.84014 6.83823 1.18275 7.51009 0.512227C7.84567 0.176964 8.23363 0 8.63192 0C8.97577 0 9.29757 0.134105 9.56287 0.387108C9.83919 0.651171 9.99424 0.994729 9.99906 1.3535C10.0046 1.75235 9.82541 2.15536 9.49534 2.48855Z" :fill="user.dailyClaims >= day ? '#fff' : '#6B7280'"/>
                      </svg>
                    </div>

                    <!-- Line connecting the circles -->
                    <div
                    v-if="day != 7"
                      class="w-6 h-1"
                      :class="user.dailyClaims >= day ? 'bg-primary-green' : 'bg-primary-gray'"
                    ></div>
                  </div>

                  <div class="text-white text-xs font-semibold -ml-1 mt-2">Day {{ day }}</div>
                </div>
              </div>
              <div class="flex items-center justify-start w-full">
                <div class="flex flex-col items-start justify-start w-2/3 px-5 mt-4 text-white font-semibold text-sm">
                  <h1 class="text-white">Ready to claim in</h1>
                  <h3 class="text-white">{{ countdown }}</h3>
                </div>
                <button
                  class="px-8 rounded-full flex items-center justify-center h-10 w-24 cursor-pointer font-bold text-white"
                  :class="isClaimable ? 'bg-blue-button' : 'bg-slate-600 bg-opacity-100'" 
                  :disabled="!isClaimable"
                  v-on:click="openModal">
                  <h1>Claim</h1>
                </button>
              </div>
              <dialog id="my_modal_5" class="modal modal-bottom sm:modal-middle rounded-t-2xl p-0">
                  <div class="modal-box h-3/5 bg-modal-bg border border-modal-border rounded-t-2xl p-0">
                    <!-- <div class="modal-action">
                    </div> -->
                    <button class="icon-button absolute top-4 right-4" aria-label="Close" v-on:click="closeModal">
                        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M18.0693 0.000495159C28.2555 0.08988 36.232 8.20359 35.995 18.4788C35.7676 28.3179 27.783 36.0673 17.8513 35.9996C7.69758 35.9318 -0.412937 27.4498 0.0162846 17.2138C0.415718 7.66998 8.3448 -0.0712836 18.0693 0.000495159ZM18.1045 21.8713C18.2507 22.0975 18.3509 22.3074 18.4999 22.4699C19.6115 23.6821 20.6988 24.9172 21.8605 26.0792C22.8517 27.0692 24.3181 27.0773 25.3552 26.2052C26.3586 25.3601 26.5969 23.9164 25.8576 22.7828C25.5529 22.3156 25.1305 21.9242 24.75 21.5097C23.7033 20.3721 22.6513 19.2413 21.5911 18.0955C22.9464 16.6342 24.2558 15.2365 25.5475 13.8226C26.4642 12.8204 26.5319 11.481 25.7533 10.4165C25.0465 9.45226 23.7128 9.07035 22.6215 9.61749C22.134 9.86262 21.7021 10.2621 21.3176 10.6617C20.1992 11.8291 19.1187 13.0317 18.0111 14.2316C16.7722 12.8976 15.5969 11.6192 14.4081 10.3542C13.6864 9.58634 12.8388 9.14619 11.7501 9.46039C9.70964 10.0482 9.06513 12.2543 10.4923 13.8348C11.7745 15.2569 13.0893 16.6491 14.4135 18.0793C14.2632 18.254 14.1386 18.4097 14.0032 18.556C12.8415 19.8155 11.6784 21.0723 10.5166 22.3318C9.44155 23.4992 9.4754 25.0892 10.5938 26.1293C11.6892 27.1478 13.2301 27.0651 14.3268 25.9072C15.5481 24.6179 16.7546 23.3137 18.1045 21.8686V21.8713Z" fill="#4C4E52"/>
                        </svg>
                    </button>
                    <div class="flex flex-col content-center justify-start items-center mt-10">
                      <h3 class="font-semibold text-4xl text-modal-text">Daily Reward</h3>
                      <div class="rounded-md ms-2 px-2 py-1">
                        <h3 class="font-semibold text-base text-primary-gray">Visit daily to claim your rewards</h3>
                      </div>
                    </div>

                    <div class="flex justify-center items-center mt-7 overflow-hidden">
                      <div v-for="i in 6" :key="i" class="h-44 relative mx-2 text-white rounded-2xl text-center flex flex-col daily-cards">
                        <div class="absolute passive-daily-cards h-44 w-full" v-if="user.dailyClaims != i"></div>
                        <div class="rounded-full h-16 w-16 border-secondary-yellow border-4 justify-center flex items-center content-center mt-5 mx-8">
                          <div class="absolute bg-secondary-yellow w-12 h-12 rounded-full"></div>
                          <div class="absolute passive-daily-cards h-12 w-12 rounded-full" v-if="user.dailyClaims != i"></div>
                          <h3 class="relative text-black font-bold text-3xl">{{ i }}</h3>
                        </div>
                        <h1 class="text-white font-semibold text-xl mt-3 py-3 mx-8">$NOV</h1>
                        <div class="rounded-b-2xl bg-white flex justify-center w-full">
                          <h1 class="text-black text-lg font-semibold">DAY {{ i }}</h1>
                        </div>
                      </div>
                    </div>
                    
                    <button class="bg-white rounded-full px-36 py-3 mt-10" v-on:click="claim">
                      <h1 class="text-black font-bold text-2xl">Claim</h1>
                    </button>

                    <h1 class="text-white text-base font-bold mt-5">00h 00m</h1>
                  </div>
              </dialog>
            </div>
            <RefClaimCard @onClaim="onRefClaim" class="mt-5" />
            <div class="mt-5 w-full reward-card bg-opacity-10 rounded-2xl p-3 flex flex-col justify-between relative overflow-hidden text-white">
              <div class="flex">
                <div class="flex flex-col justify-start items-start">
                  <h3 class="text-white text-lg font-semibold">Daily Combo</h3>
                  <h2 class="text-[#8E96A6] font-medium text-start">Sort numbers correctly</h2>
                </div>
                <div class="flex justify-end w-full">
                  <h1 class="text-xl font-bold text-white mt-2">{{ store.state.comboCountdown }}</h1>
                </div>
              </div>
              <div class="flex justify-between items-center w-full mt-5">
                <div class="flex gap-1 text-white items-center justify-center">
                  Claim
                  <h3>50</h3>
                  <img src="../assets/novas.svg" alt="appicon" class="h-4 w-4 rounded-full">
                </div>
                <button
                  class="px-8 rounded-xl flex items-center justify-center ms-3 h-10 w-24 cursor-pointer font-bold text-white"
                  :class="user.isComboClaimed || user.comboAttemptsLeft <= 0 ? 'bg-slate-600 bg-opacity-100' : ' bg-[#3F71D4] shadow-[#3F71D4] shadow-md'"
                  v-on:click="showComboModal"
                  >
                  <h1>{{ user.isComboClaimed || user.comboAttemptsLeft <= 0 ? 'Claimed!' : 'Play' }}</h1>
                </button>
              </div>
            </div>
            <dialog id="reward_modal" class="bg-modal-bg p-8 rounded-lg border-modal-border">
                <form method="dialog">
                  <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 text-white" @click="closeModal">✕</button>
                  <h3 class="font-semibold text-2xl text-primary-green">Congratulations!</h3>
                  <p class="py-4 text-white">You have earned {{ reward?.value ?? 0 }} $NOV</p>
                  <button class="btn bg-primary-yellow border-none" @click="fortuneClaim(reward?.value)">Claim</button>
                </form>
              </dialog>
            <dialog id="wheel_modal" class="modal modal-bottom sm:modal-middle rounded-t-2xl p-0">
                <div class="modal-box h-3.5/5 bg-modal-bg border border-modal-border rounded-t-2xl p-0">
                  <button class="icon-button absolute top-4 right-4" aria-label="Close" v-on:click="closeWheelModal">
                      <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.0693 0.000495159C28.2555 0.08988 36.232 8.20359 35.995 18.4788C35.7676 28.3179 27.783 36.0673 17.8513 35.9996C7.69758 35.9318 -0.412937 27.4498 0.0162846 17.2138C0.415718 7.66998 8.3448 -0.0712836 18.0693 0.000495159ZM18.1045 21.8713C18.2507 22.0975 18.3509 22.3074 18.4999 22.4699C19.6115 23.6821 20.6988 24.9172 21.8605 26.0792C22.8517 27.0692 24.3181 27.0773 25.3552 26.2052C26.3586 25.3601 26.5969 23.9164 25.8576 22.7828C25.5529 22.3156 25.1305 21.9242 24.75 21.5097C23.7033 20.3721 22.6513 19.2413 21.5911 18.0955C22.9464 16.6342 24.2558 15.2365 25.5475 13.8226C26.4642 12.8204 26.5319 11.481 25.7533 10.4165C25.0465 9.45226 23.7128 9.07035 22.6215 9.61749C22.134 9.86262 21.7021 10.2621 21.3176 10.6617C20.1992 11.8291 19.1187 13.0317 18.0111 14.2316C16.7722 12.8976 15.5969 11.6192 14.4081 10.3542C13.6864 9.58634 12.8388 9.14619 11.7501 9.46039C9.70964 10.0482 9.06513 12.2543 10.4923 13.8348C11.7745 15.2569 13.0893 16.6491 14.4135 18.0793C14.2632 18.254 14.1386 18.4097 14.0032 18.556C12.8415 19.8155 11.6784 21.0723 10.5166 22.3318C9.44155 23.4992 9.4754 25.0892 10.5938 26.1293C11.6892 27.1478 13.2301 27.0651 14.3268 25.9072C15.5481 24.6179 16.7546 23.3137 18.1045 21.8686V21.8713Z" fill="#4C4E52"/>
                      </svg>
                  </button>
                  <div class="flex flex-col content-center justify-center items-center mt-10 relative">
                    <FortuneWheel
                      ref="wheelEl"
                      id="wheel"
                      type="image"
                      :useWeight="true"
                      :verify="canvasVerify"
                      :prizeId="prizeId"
                      :angleBase="-2"
                      :prizes="prizesImage"
                      @rotateStart="onImageRotateStart"
                      @rotateEnd="onRotateEnd"
                      >
                      <template #wheel>
                        <div class="w-full flex justify-center"> 
                          <img src="../assets/wheel.svg" style="width: 95%; transform: rotateZ(380deg)" />
                        </div>
                      </template>
                    </FortuneWheel>
                    <img src="../assets/wheel_btn.svg" style="width: 100px" class="absolute"/>
                  </div>
                  
                  <button class="bg-white rounded-full px-36 py-3 mt-10" v-on:click="turn">
                    <h1 class="text-black font-bold text-2xl">Play!</h1>
                  </button>

                  <h1 class="text-white text-base font-bold mt-5">00h 00m</h1>
                </div>
            </dialog>
          <dialog id="status_modal" class="modal text-white">
            <div class="modal-box h-2/5 rounded-t-2xl p-0 bg-slate-900">
              <button class="icon-button absolute top-4 right-4" aria-label="Close" v-on:click="closeAttemptModal">
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18.0693 0.000495159C28.2555 0.08988 36.232 8.20359 35.995 18.4788C35.7676 28.3179 27.783 36.0673 17.8513 35.9996C7.69758 35.9318 -0.412937 27.4498 0.0162846 17.2138C0.415718 7.66998 8.3448 -0.0712836 18.0693 0.000495159ZM18.1045 21.8713C18.2507 22.0975 18.3509 22.3074 18.4999 22.4699C19.6115 23.6821 20.6988 24.9172 21.8605 26.0792C22.8517 27.0692 24.3181 27.0773 25.3552 26.2052C26.3586 25.3601 26.5969 23.9164 25.8576 22.7828C25.5529 22.3156 25.1305 21.9242 24.75 21.5097C23.7033 20.3721 22.6513 19.2413 21.5911 18.0955C22.9464 16.6342 24.2558 15.2365 25.5475 13.8226C26.4642 12.8204 26.5319 11.481 25.7533 10.4165C25.0465 9.45226 23.7128 9.07035 22.6215 9.61749C22.134 9.86262 21.7021 10.2621 21.3176 10.6617C20.1992 11.8291 19.1187 13.0317 18.0111 14.2316C16.7722 12.8976 15.5969 11.6192 14.4081 10.3542C13.6864 9.58634 12.8388 9.14619 11.7501 9.46039C9.70964 10.0482 9.06513 12.2543 10.4923 13.8348C11.7745 15.2569 13.0893 16.6491 14.4135 18.0793C14.2632 18.254 14.1386 18.4097 14.0032 18.556C12.8415 19.8155 11.6784 21.0723 10.5166 22.3318C9.44155 23.4992 9.4754 25.0892 10.5938 26.1293C11.6892 27.1478 13.2301 27.0651 14.3268 25.9072C15.5481 24.6179 16.7546 23.3137 18.1045 21.8686V21.8713Z" fill="#4C4E52"/>
                  </svg>
              </button>
              <div class="p-4 w-full flex flex-col h-full justify-center items-center">
                <h1 class="text-2xl mb-4">Status</h1>
                <p>{{ comboStatus ? 'You won!' : 'Please try again!' }}</p>
                <button class="bg-white rounded-full px-24 py-3 mt-10" v-on:click="closeAttemptModal">
                  <h1 class="text-black font-bold text-2xl">Ok</h1>
                </button>
              </div>
            </div>
          </dialog>
            <dialog id="combo_modal" class="modal modal-bottom sm:modal-middle rounded-t-2xl p-0 text-white">
              <div class="modal-box h-4/5 rounded-t-2xl p-0 bg-slate-900">
                <button class="icon-button absolute top-4 right-4" aria-label="Close" v-on:click="closeComboModal">
                      <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.0693 0.000495159C28.2555 0.08988 36.232 8.20359 35.995 18.4788C35.7676 28.3179 27.783 36.0673 17.8513 35.9996C7.69758 35.9318 -0.412937 27.4498 0.0162846 17.2138C0.415718 7.66998 8.3448 -0.0712836 18.0693 0.000495159ZM18.1045 21.8713C18.2507 22.0975 18.3509 22.3074 18.4999 22.4699C19.6115 23.6821 20.6988 24.9172 21.8605 26.0792C22.8517 27.0692 24.3181 27.0773 25.3552 26.2052C26.3586 25.3601 26.5969 23.9164 25.8576 22.7828C25.5529 22.3156 25.1305 21.9242 24.75 21.5097C23.7033 20.3721 22.6513 19.2413 21.5911 18.0955C22.9464 16.6342 24.2558 15.2365 25.5475 13.8226C26.4642 12.8204 26.5319 11.481 25.7533 10.4165C25.0465 9.45226 23.7128 9.07035 22.6215 9.61749C22.134 9.86262 21.7021 10.2621 21.3176 10.6617C20.1992 11.8291 19.1187 13.0317 18.0111 14.2316C16.7722 12.8976 15.5969 11.6192 14.4081 10.3542C13.6864 9.58634 12.8388 9.14619 11.7501 9.46039C9.70964 10.0482 9.06513 12.2543 10.4923 13.8348C11.7745 15.2569 13.0893 16.6491 14.4135 18.0793C14.2632 18.254 14.1386 18.4097 14.0032 18.556C12.8415 19.8155 11.6784 21.0723 10.5166 22.3318C9.44155 23.4992 9.4754 25.0892 10.5938 26.1293C11.6892 27.1478 13.2301 27.0651 14.3268 25.9072C15.5481 24.6179 16.7546 23.3137 18.1045 21.8686V21.8713Z" fill="#4C4E52"/>
                      </svg>
                </button>
                <div class="container mx-auto p-4 w-screen">
                  <h1 class="text-2xl mb-4">Daily Combo Cards</h1>
                  <div class="flex justify-center w-full ms-1 mt-14">
                    <DropZone :cards="cards" @update:cards="updateCards" />
                  </div>
                  <h3 class="font-normal text-base mt-10" v-if="!user.isComboClaimed">{{ user.comboAttemptsLeft }} Attempts Left</h3>
                  <h3 class="font-normal text-base mt-10" v-else>Already claimed</h3>
                  <h4 class="font-normal text-base mt-10">Complete combination</h4>
                  <button class="bg-white rounded-full w-full py-3 mt-10" v-on:click="tryCombo" v-if="!user.isComboClaimed && user.comboAttemptsLeft != 0">
                    <h1 class="text-black font-bold text-xl">Try Chance</h1>
                  </button>
                  <button v-else-if="user.isComboClaimed || user.comboAttemptsLeft == 0" disabled class="bg-gray-400 rounded-full w-full py-3 mt-10">
                    <h1 class="text-black font-bold text-xl">Already Tried</h1>
                  </button>
                </div>
              </div>
            </dialog>
          </div>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
    </div>
</template>

<script setup>
import RefClaimCard from '../components/RefClaimCard.vue';
import FortuneWheel from 'vue-fortune-wheel';
import UserService from '@/service/userService';
import AdService from '@/service/adService.js';
import initFirebase from '../firebase';
import { logEvent } from 'firebase/analytics';
import { useToast } from 'vue-toast-notification';
import store from '../store/index';
import { computed, ref } from 'vue';
import DropZone from '@/components/DropZone.vue';

const { analytics } = initFirebase();
const $toast = useToast();

const prizeId = ref(0)
const canvasVerify = ref(false)
const prizesImage = [
    {
      id: 1,
      value: 50, 
      weight: 5,
    },
    {
      id: 2,
      value: 100,
      weight: 3
    },
    {
      id: 3,
      value: 200,
      weight: 3
    },
    {
      id: 4, 
      value: 300,
      weight: 2 
    },
    {
      id: 5,
      value: 120,
      weight: 3
    },
    {
      id: 6,
      value: 10,
      weight: 4
    }
];

const wheelEl = ref()

const reward = ref();
const cards = ref([
  { index: 1 },
  { index: 2 },
  { index: 3 },
  { index: 4 },
  { index: 5 },
]);
const comboStatus = ref();

const user = computed(() => {
  return store.state.user;
});

const updateCards = (newCards) => {
  cards.value = newCards;
};

const countdown = computed(() => {
  return store.state.nextDailyClaimCountdown;
});

const wheelCountdown = computed(() => {
  return store.state.wheelCountDown;
})

const isClaimable = computed(() => {
  if(user.value.dailyClaims == 0 || user.value.dailyClaims == []) return true;

  return store.state.nextDailyClaim <= new Date();
});

const isWheelClaimable = computed(() => {
  return store.state.nextWheelClaim <= new Date();
});


const openModal = () => {
  document.getElementById('my_modal_5').showModal();
};

const closeModal = () => {
  document.getElementById('my_modal_5').close();
};

const openWheelModal = () => {
  document.getElementById('wheel_modal').showModal();
};

const closeWheelModal = () => {
  document.getElementById('wheel_modal').close();
};

const onRotateEnd = (prize) => {
  reward.value = prize;

  document.getElementById('reward_modal').showModal();
};

const onImageRotateStart = () => {
  console.log('Rotate started');
};

const turn = () => {
  console.log(wheelEl.value.startRotate());
};

const claim = async () => {
  closeModal();
  
  try {
    await AdService.showAd();
  } catch(e) {
    console.log(e);
  }

  const res = await UserService.claimDaily(user.value.id);
  
  if(res.error) {
      $toast.error('Something went wrong, please try again later', { position: 'top', duration: 1500 });

      return;
  } 

  const data = await UserService.getUser(user.value.id);

  logEvent(analytics, 'daily_claimed', {
      user_id: user.value.id,
  });

  $toast.success('Claimed successfully', { position: 'top', duration: 1500 });
  
  const nextClaim = data.nextClaim;
  const nextDailyClaim = data.nextDailyClaim;
  const nextWheelClaim = data.nextWheelClaim;
  const nextClaimDate = new Date(nextClaim);
  const nextDailyClaimDate = new Date(nextDailyClaim);
  const nextWheelClaimDate = new Date(nextWheelClaim);
  
  store.commit('setUser', data.user);
  store.commit('setCanClaim', false);
  store.commit('setNextClaim', nextClaimDate);
  store.commit('setNextWheelClaim', nextWheelClaimDate);
  store.commit('setNextDailyClaim', nextDailyClaimDate);

  const dailyCountDownInterval = setInterval(() => {
      const currentTime = new Date().getTime();
      const timeLeft = nextDailyClaimDate - currentTime;
      
      if (timeLeft < 0) {
        store.commit('setDailyCountdown', '00:00:00');

        clearInterval(store.state.dailyCountDownInterval);

        return;
      }

      const hours = Math.floor(timeLeft / (1000 * 60 * 60)).toString().padStart(2, '0');
      const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0');
      const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000).toString().padStart(2, '0');

      store.commit('setDailyCountdown', `${hours}:${minutes}:${seconds}`);
  }, 1000);

  store.commit('setDailyCountDownInterval', dailyCountDownInterval);
};

const onRefClaim = async (level) => {
    try {
        await AdService.showAd();
    } catch(e) {
        console.log(e);
    }

    const res = await UserService.claimRef(user.value.id, level);

    if(!res) {
        $toast.error('Something went wrong, please try again later', { position: 'top', duration: 1500 });

        return;
    } 

    logEvent(analytics, 'ref_claimed', {
        user_id: user.value.id,
        level: level,
    });

    store.commit('setUser', res);
    
    $toast.success('Claimed successfully', { position: 'top', duration: 1500 });
};

const fortuneClaim = async (value) => {
    closeWheelModal();

    try {
      await AdService.showAd();
    } catch(e) {
      console.log(e);
    }  

    try {
      await UserService.claimWheelReward(user.value.id, value);

      const data = await UserService.getUser(user.value.id); 

      $toast.success('Claimed successfully', { position: 'top', duration: 1500 });

      const nextClaim = data.nextClaim;
      const nextDailyClaim = data.nextDailyClaim;
      const nextWheelClaim = data.nextWheelClaim;
      const nextClaimDate = new Date(nextClaim);
      const nextDailyClaimDate = new Date(nextDailyClaim);
      const nextWheelClaimDate = new Date(nextWheelClaim);
      
      store.commit('setUser', data.user);
      store.commit('setCanClaim', false);
      store.commit('setNextClaim', nextClaimDate);
      store.commit('setNextWheelClaim', nextWheelClaimDate);
      store.commit('setNextDailyClaim', nextDailyClaimDate);

      reward.value = null;
        
      logEvent(analytics, 'wheel_claimed', {
        user_id: user.value.id,
      });

      const wheelCountdownInterval = setInterval(() => {
          const currentTime = new Date().getTime();
          const timeLeft = nextWheelClaimDate - currentTime;
          
          if (timeLeft < 0) {
            this.$store.commit('setWheelCountDown', '00:00:00');

            clearInterval(store.state.wheelCountDownInterval);

            return;
          }

          const hours = Math.floor(timeLeft / (1000 * 60 * 60)).toString().padStart(2, '0');
          const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0');
          const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000).toString().padStart(2, '0');

          store.commit('setWheelCountDown', `${hours}:${minutes}:${seconds}`);
      }, 1000);

      store.commit('setWheelCountDownInterval', wheelCountdownInterval);
    } catch(e) {
      console.log(e);
      $toast.error('Something went wrong, please try again later');
    }
}

const showComboModal = () => {
  const modal = document.getElementById('combo_modal');

  modal.showModal();
}

const closeComboModal = () => {
  document.getElementById('combo_modal').close();
}

const showAttemptModal = () => {
  const modal = document.getElementById('status_modal');

  modal.showModal();
}

const closeAttemptModal = () => {
  const modal = document.getElementById('status_modal');

  modal.close();

  if(comboStatus.value) {
    closeComboModal();
  }
}

const tryCombo = async () => {
  closeComboModal();

  try {
    await AdService.showAd();
  } catch(e) {
    console.log(e); 
  }

  try {
    const comboIds = cards.value.map(card => card.index).join('');
    console.log(comboIds);

    const res = await UserService.claimCombo(store.state.user.id, comboIds);

    comboStatus.value = res.success;

    if(res.user) {
      store.commit('setUser', res.user);
      store.commit('setNextComboClaim', new Date(res.nextComboClaim).getTime());

      const comboCountdownInterval = setInterval(() => {
        const currentTime = new Date().getTime();
        const timeLeft = new Date(res.nextComboClaim).getTime() - currentTime;
        
        if (timeLeft < 0) {
          store.commit('setComboCountdown', '00:00:00');
          clearInterval(comboCountdownInterval);
          return;
        }

        const hours = Math.floor(timeLeft / (1000 * 60 * 60)).toString().padStart(2, '0');
        const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0');
        const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000).toString().padStart(2, '0');

        store.commit('setComboCountdown', `${hours}:${minutes}:${seconds}`);
      }, 1000);

      store.commit('setComboCountDownInterval', comboCountdownInterval);
    }

    showAttemptModal();
  } catch(e) {
    comboStatus.value = false;
    return e;
  }
}
</script>